body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow-x: hidden;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/fonts/Inter.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik"), url("./assets/fonts/Rubik.ttf") format("truetype");
}
