.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
  border-radius: 3px;
  border-color: "black";
}

.carousel-image {
  height: 270px;
  width: 100%;
  transition: transform 0.5s ease-in-out;
  object-fit: cover;
  cursor: pointer;
  border-radius: 3px;
  @media (max-width: 1224px) {
    height: 290px;
  }
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.carousel-button:nth-child(1) {
  left: 10px;
}

.carousel-button:nth-child(3) {
  right: 10px;
}

.badge {
  display: flex;
  position: absolute;
  background-color: #232532;
  opacity: 0.6;
  flex-direction: column;
  align-items: "center";
  align-content: "center";
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3% 5% 3% 5%;
  bottom: 0;
}

.badge-title {
  color: white;
  font-size: 15px;
  font-weight: 800;
  margin-bottom: 5px;
}

.badge-subtitle {
  color: rgb(6, 6, 6);
  font-size: 10px;
  font-weight: 600;
  background: #dfec49;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
}

.badge-subtitle:hover {
  color: white;
  background: rgba(233, 221, 221, 0.2);
}
